import React from 'react';
import ReactDOM from 'react-dom';
import { Linktree, CHANNEL_TYPES } from 'linkees';

import './css/normalize.css';

const items = [
  {
    title: 'Twitter',
    subtitle: '@sapilauts',
    type: CHANNEL_TYPES.TWITTER,
    link: 'https://twitter.com/sapilauts', // twitter profile link
  },
  {
    title: 'Instagram',
    subtitle: '@sapilauts',
    type: CHANNEL_TYPES.INSTAGRAM,
    link: 'https://www.instagram.com/sapilauts/', //instagram profile link
  },
  {
    title: 'Facebook',
    subtitle: '@ionehr',
    type: CHANNEL_TYPES.LINKEDIN,
    link: 'https://www.facebook.com/ionehr', // Facebook
  },
  {
    title: 'YouTube',
    subtitle: '@sapilauts',
    type: CHANNEL_TYPES.YOUTUBE,
    link: 'https://www.youtube.com/@sapilauts', //youtube channel link
  },
  {
    title: 'Telegram',
    subtitle: '@sapilauts',
    type: CHANNEL_TYPES.TELEGRAM,
    link: 'https://telegram.me/sapilauts', //Telegram Pofile
  },
  {
    title: 'Crypto Addresses',
    subtitle: 'Ethereum and Polygon',
    type: CHANNEL_TYPES.DRIBBLE,
    link: 'https://sapilauts.crypto', // Crypto Addresses
  },

];

ReactDOM.render(
  <React.StrictMode>
    <Linktree cardItems={items} />
  </React.StrictMode>,
  document.getElementById('root')
);
